/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.30/esri/copyright.txt for details.
*/
const i = {
    hide: "hide",
    hideChildren: "hide-children"
  },
  n = 10,
  e = 2;
function t({
  exclusive: i,
  visible: n,
  visibilityAppearance: e
}) {
  const t = "checkbox" === e;
  return n ? i ? "circle-f" : t ? "check-square-f" : "view-visible" : i ? "circle" : t ? "square" : "view-hide";
}
function r({
  connectionStatus: i,
  publishing: n
}) {
  return i ? "connected" === i ? "beacon" : "offline" : n ? "square" : "bullet-point";
}
function l(n) {
  return n?.listMode === i.hideChildren;
}
function u(i) {
  return i?.listMode ?? void 0;
}
function s(i) {
  if (!i) return "inherited";
  const n = b(p(i) ? i.layer : i);
  return null != n ? n ? "independent" : "inherited" : "visibilityMode" in i && null != i.visibilityMode ? i.visibilityMode : "independent";
}
function o(i) {
  if (i && (!("type" in i) || "wmts" !== i.type)) return "sublayers" in i ? "sublayers" : "layers" in i ? "layers" : void 0;
}
function a(i) {
  const n = (i?.layer && p(i.layer) ? i.layer.layer : void 0) ?? i?.layer;
  return !!n && "catalog" !== n.type && (h(n) ?? !0);
}
function c(n) {
  return u(n) !== i.hide;
}
function d(i, n) {
  i?.sort((i, e) => {
    const t = "uid" in i ? n.indexOf(i.uid) : -1,
      r = "uid" in e ? n.indexOf(e.uid) : -1;
    return t > r ? -1 : t < r ? 1 : 0;
  });
}
function y(i, n) {
  const e = i?.layer;
  if (!e) return;
  const t = o(e);
  if (!t) return;
  let r;
  "layers" === t && "layers" in e ? r = e.layers : "sublayers" === t && "sublayers" in e && (r = e.sublayers), d(r, n);
}
function f(i) {
  const n = i?.layer;
  return n && "layers" in n ? n.layers : null;
}
function p(i) {
  return null != i && "layer" in i && null != i.layer;
}
function b(i) {
  const n = v(i);
  return null != n && "supportsSublayerVisibility" in n ? n.supportsSublayerVisibility : void 0;
}
function h(i) {
  const n = v(i);
  return null != n && "supportsDynamicLayers" in n ? n.supportsDynamicLayers : void 0;
}
function v(i) {
  return i && "capabilities" in i && null != i.capabilities && "exportMap" in i.capabilities ? i.capabilities.exportMap : void 0;
}
function x(i) {
  return i?.["data-item"];
}
function M(i) {
  return i?.getAttribute("data-layer-type");
}
function m({
  children: i,
  error: n,
  incompatible: e
}) {
  return !!i?.filter(i => !i.hidden).length && !n && !e;
}
export { l as areChildrenHidden, c as canDisplayLayer, a as canSortSublayers, u as findLayerListMode, s as findLayerVisibilityMode, x as getItem, f as getItemLayers, M as getLayerType, o as getNormalizedChildLayerProperty, r as getStatusIcon, t as getVisibilityIcon, p as isSublayer, m as listItemHasChildren, e as minDragEnabledItems, n as minFilterItems, y as sortChildLayersToIds, d as sortLayersToIds };